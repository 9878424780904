<template>
  <div>
    <template v-if="$store.getters['app/isTestEnded']">
      <TestEnded/>
    </template>
    <template v-else>
      <div role="main" id="highlightable-content">
        <div class="itemBody activeItem" itemNumber="1" partNumber="N/A"
             sectionName="Part 1" identifier="IELTGWCB-GTWSAMPLE1-0-0" style="">
          <div class="container">
            <div class="rubricBlock" view="candidate">
              <p>
                <strong>
                  <span author-class="fs14pt">Part {{part[display_quest.part_no - 1].no}}</span>
                </strong>
                <br/>
                <br/>
                {{part[display_quest.part_no - 1].part_note}}
              </p>
            </div>
          </div>
          <div class="container scroll-content" id="mainContent">
            <div author-class="container genericTwoColumn split-5050">
              <div author-class="left" class="scroll-container" v-html="part[display_quest.part_no - 1].part_content"/>
              <div author-class="right" class="scroll-container" >
                <div author-class="presentation-fullHeight"
                     class="extendedTextInteraction blockInteraction stringInteraction interaction inFocus">
                  <textarea cols="70" rows="6" v-model="display_quest.answer"></textarea>
                  <span class="wordCount">Word count: <span class="value">{{countWord(display_quest.answer)}}</span></span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation
          :questions="questions"
          :focus-quest-no="focusQuestNo"
          @changeQuest="changeQuest"/>
      <right-click/>
    </template>

  </div>
</template>

<script>
import Navigation from "@/views/components/global/navigation";
import TestEnded from "@/views/components/global/TestEnded";

import { bus } from "@/main";
import RightClick from "@/views/components/global/RightClick";

export default {
  name: "WritingMain",
  components: {RightClick, TestEnded, Navigation},
  data() {
    return {
      part: [
        {
          no: 1,
          type: "Writing",
          part_note: "You should spend about 20 minutes on this part. Write at least 150 words.",
          part_content: "<p><strong>You\n" +
              "            live in a room in college which you share with another student. <br/>However, there are many problems with\n" +
              "            this arrangement and you find it <br/>very difficult to work.</strong><br/><br/><strong>Write a letter to\n" +
              "            the accommodation officer at the college. In your letter</strong><br/></p>\n" +
              "            <ul>\n" +
              "              <li><p><strong>describe the situation</strong></p></li>\n" +
              "              <li><p><strong>explain your problems and why it is difficult to work </strong></p></li>\n" +
              "              <li><p><strong>say what kind of accommodation you would prefer</strong></p></li>\n" +
              "            </ul>\n" +
              "            <p>\n" +
              "              You do <strong>NOT</strong> need to write any addresses.<br/><br/>Begin your letter as follows:<br/><br/>Dear\n" +
              "              Sir or Madam,</p>",
          questions: [
            {
              id: 1,
              content: '',
              answer: ''
            },
          ]
        },
        {
          no: 2,
          part_note: "You should spend about 40 minutes on this task. Write at least 250 words.",
          part_content: `<p xmlns="http://www.w3.org/1999/xhtml">Write about the following topic:<br/><br/><strong>The average standard of
          people's health is likely to be lower in the future than it is now.</strong><br/><br/><strong>To what extent do
          you agree or disagree with this statement?</strong><br/><br/>Give reasons for your answer and include any relevant
    examples from your own knowledge or experience.</p>`,
          questions: [
            {
              id: 1,
              content: '',
              answer: ''
            },
          ]
        }
      ],
      focusQuestNo: 1,
      display_quest: {
        type: '',
        part_no: 1
      },
      questions: [

      ]
    }
  },
  created() {
    this.$nextTick(() => {
      bus.$emit('startCountdown')
      bus.$emit('startCountdown')
    })
    this.init()
    bus.$on('changeQuest', this.changeQuest)

  },
  methods: {

    changeQuest(no) {
      this.focusQuestNo = no
      this.display_quest = this.questions[no - 1]
    },
    countWord (word) {
      return word ? word.split(' ').filter(w => w).length : 0
    },
    init() {
      this.part.forEach(p => {
        p.questions.map(q => q.part_no = p.no)
        this.questions = this.questions.concat(p.questions)
      })
      this.display_quest = this.questions[0]
    }
  }
}
</script>

<style scoped>

</style>
